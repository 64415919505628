<template>
	<v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline" dark>
         FORGOT PASSWORD
        </v-card-title>
        <v-card-text>
					<!-- <h3>Please, input valid email:</h3> -->
					<div v-if="!emailForgotSent">
						<v-form ref="formForgot" v-model="formForgotValidity">
							<v-text-field
								prepend-icon="mdi-email-outline"
								label="Email registered"
								v-model="emailForgot"
								:rules="[rules.required, rules.email]"
							/>

							<v-btn
								:loading="sending"
								@click.stop="sendNewPassword()"
								:disabled="!formForgotValidity"
								rounded
								color="primary accent-3">
								SEND
							</v-btn>
						</v-form>
					</div>
					<div v-else>
						<v-alert dense text type="success" class="mt-4">
							 <strong>PASSWORD GENERATED</strong> Check your email!
						</v-alert>
					</div>
				</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
						x-large
						text
            color="accent darken-1 white--text"
						@click="closeDialog"
          >
            CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import axios from "axios";
	import store from '@/store';
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	export default {
		name: 'forgotPassword',
		props: [
			'dialog'
		],
		data: ()=>({
			sending: false,
			emailForgot: '',
			formForgotValidity: false,
			emailForgotSent: false,
			rules: {
				required: value => !!value || 'Required.',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalido email.'
				},
				password: value => value.length >= 6 || 'Contraseña obligatoria',
			},
		}),
		methods: {
			async sendNewPassword(){
				this.sending = true;
				let me = this;
				me.emailForgot = me.emailForgot.trim().toLowerCase()

				try {
					let response = await axios.post('user/forgot',{
						email: me.emailForgot
					})
					me.$store.commit('SET_ALERT_MESSAGE', {
						show: true, color: 'success',
						message: 'Password generated successful, check your email',
						timeout: 4000
					}, {root: true})
				} catch (e) {
					me.$store.commit('SET_ALERT_MESSAGE', {
						show: true, color: 'error',
						message: e.response.statusText,
						timeout: 4000
					}, {root: true})
				} finally {
					me.sending = false;
					me.closeDialog();
				}

			},
			closeDialog() {
				this.emailForgotSent = false
				this.emailForgot = ''
				this.formForgotValidity = false
				this.$refs.formForgot.reset()
				this.$refs.formForgot.resetValidation()
				this.$emit('dialogForgotShow', false);
				this.dialogShow = false;
			},
		},
		computed: {

		}

	}
</script>

<style lang="scss" scoped>

</style>