<template>
  <v-sheet height="100vh" tile class="grey darken-3">
    <v-container fill-height fluid>
      <v-row class="text-center" justify="center">
        <v-col cols="12" sm="10" md="6">
          <v-card elevation="10">
            <v-card-title class="dark primary white--text">LOGIN</v-card-title>
          <v-img
            src="@/assets/logo.png"
            contain
            height="140"
          />
          <span class="grey--text">Data intelligence platform</span>
            <v-card-text>
              <v-container>
                <v-row justify="center">
                  <v-col cols="12" sm="8">
                    <v-form v-model="formValidity">
                      <v-row justify="center" align="center" style="background: #FAFAFA">
                        <v-col cols="12">
                          <v-text-field
                              label="Email"
                              prepend-icon="mdi-account-circle"
                              :rules="[rules.required, rules.email]"
                              v-model="email"
                              required
                              color="primary"
                          />
                          <v-text-field
                              label="Password"
                              type="Password"
                              prepend-icon="mdi-lock"
                              :rules="[rules.required]"
                              v-model="password"
                              required
                              color="primary"
                          />
                          <v-btn
                              :loading="loading"
                              x-large
                              color="primary"
                              class="white--text"
                              :disabled="!formValidity"
                              @click.stop="login()">
                            LOGIN
                          </v-btn>
                        </v-col>
                        <v-col cols="12">
                          <div
                            class="my-3 text-right grey--text"
                            role="button"
                            @click="dialog=true"
                          >
                            Forgot Password?
                          </div>
                        </v-col>
                      </v-row>

                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-text>
              <h3>Don't have an account?
               <span
                  class="primary--text"
                  role="button"
                  @click="$router.push({ name: 'Register' })"
                >
                  Create one
                </span>
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <fogot-password-dialog
        :dialog="dialog"
        @dialogForgotShow="setDialogForgotShow"
      />

      <!-- <Notification /> -->
      <v-snackbar
          top
          right
          :timeout="alert.timeout"
          :color="alert.color"
          v-model="alert.show">
        {{ alert.message }}
      </v-snackbar>
    </v-container>
  </v-sheet>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import FogotPasswordDialog from '@/components/dialog/FogotPasswordDialog.vue';
export default {
  components: { FogotPasswordDialog },
  name: 'Login',
  created(){
  },
  data: () => ({
    dialog: false,
    loading: false,
    formValidity: false,
    email: '',
    password: '',
    rules: {
      required: value => !!value || 'Required.',
      counter: value => value.length <= 20 || 'Max 20 characters',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
    },
  }),
  methods: {
    login(){
      const me = this;
      me.loading = true
      me.email = me.email.trim().toLowerCase()
      me.password = me.password.trim()
      axios.post('user/login',{
        email: me.email, password: me.password
      })
          .then( response => { return response.data })
          .then(data =>{
            this.$store.dispatch("authModule/saveToken",data.tokenReturn);
            me.loaging = false
            me.$router.push({name: 'Dashboard'});
          })
          .catch( function(e){
            let errorM = null
            if (e.response.status==404){
              errorM=e.response.statusText;
            } else{
              errorM='Internal Server Error';
            }
            me.loading = false
            me.$store.commit('SET_ALERT_MESSAGE', {
              show: true,
              color: 'error',
              message: errorM,
              timeout: 4000
            }, {root: true})

          });
    },
    setDialogForgotShow(value) {
      this.dialog = value
    },
  },
  computed:{
    ...mapState(['alert']),
  }
}
</script>
